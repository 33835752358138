<form #form (ngSubmit)="submit()" ngNativeValidate [appApiAction]="formPromise">
    <div class="page-header">
        <h1>{{'exportVault' | i18n}}</h1>
    </div>
    <p>{{'exportMasterPassword' | i18n}}</p>
    <div class="row">
        <div class="form-group col-6">
            <label for="format">{{'fileFormat' | i18n}}</label>
            <select class="form-control" id="format" name="Format" [(ngModel)]="format">
                <option value="json">.json</option>
                <option value="csv">.csv</option>
                <option value="encrypted_json">.json (Encrypted)</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-6">
            <label for="masterPassword">{{'masterPass' | i18n}}</label>
            <input id="masterPassword" type="password" name="MasterPassword" class="form-control"
                [(ngModel)]="masterPassword" required appInputVerbatim>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span>{{'exportVault' | i18n}}</span>
    </button>
</form>
