<div class="page-header">
    <h1>{{'vault' | i18n}}</h1>
</div>
<div class="mt-4">
    <ng-container *ngIf="ciphers.length">
        <table class="table table-hover table-list table-ciphers">
            <tbody>
                <tr *ngFor="let c of ciphers">
                    <td class="table-list-icon">
                        <app-vault-icon [cipher]="c"></app-vault-icon>
                    </td>
                    <td class="reduced-lh wrap">
                        <a href="#" appStopClick (click)="selectCipher(c)" title="{{'editItem' | i18n}}">{{c.name}}</a>
                        <ng-container *ngIf="!organization && c.organizationId">
                            <i class="fa fa-share-alt" appStopProp title="{{'shared' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'shared' | i18n}}</span>
                        </ng-container>
                        <ng-container *ngIf="c.hasAttachments">
                            <i class="fa fa-paperclip" appStopProp title="{{'attachments' | i18n}}"
                                aria-hidden="true"></i>
                            <span class="sr-only">{{'attachments' | i18n}}</span>
                        </ng-container>
                        <br>
                        <small>{{c.subTitle}}</small>
                    </td>
                    <td class="table-list-options">
                        <div class="dropdown" appListDropdown *ngIf="c.hasAttachments">
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                appA11yTitle="{{'options' | i18n}}">
                                <i class="fa fa-cog fa-lg" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" appStopClick (click)="viewAttachments(c)">
                                    <i class="fa fa-fw fa-paperclip" aria-hidden="true"></i>
                                    {{'attachments' | i18n}}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<ng-template #cipherAddEdit></ng-template>
<ng-template #attachments></ng-template>
