<div class="page-header d-flex">
    <h1>{{'groups' | i18n}}</h1>
    <div class="ml-auto d-flex">
        <div>
            <label class="sr-only" for="search">{{'search' | i18n}}</label>
            <input type="search" class="form-control form-control-sm" id="search" placeholder="{{'search' | i18n}}"
                [(ngModel)]="searchText">
        </div>
        <button type="button" class="btn btn-sm btn-outline-primary ml-3" (click)="add()">
            <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
            {{'newGroup' | i18n}}
        </button>
    </div>
</div>
<ng-container *ngIf="loading">
    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
    <span class="sr-only">{{'loading' | i18n}}</span>
</ng-container>
<ng-container *ngIf="!loading && (isPaging() ? pagedGroups : groups | search:searchText:'name':'id') as searchedGroups">
    <p *ngIf="!searchedGroups.length">{{'noGroupsInList' | i18n}}</p>
    <table class="table table-hover table-list" *ngIf="searchedGroups.length" infiniteScroll
        [infiniteScrollDistance]="1" [infiniteScrollDisabled]="!isPaging()" (scrolled)="loadMore()">
        <tbody>
            <tr *ngFor="let g of searchedGroups">
                <td>
                    <a href="#" appStopClick (click)="edit(g)">{{g.name}}</a>
                </td>
                <td class="table-list-options">
                    <div class="dropdown" appListDropdown>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" appA11yTitle="{{'options' | i18n}}">
                            <i class="fa fa-cog fa-lg" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" appStopClick (click)="users(g)">
                                <i class="fa fa-fw fa-users" aria-hidden="true"></i>
                                {{'users' | i18n}}
                            </a>
                            <a class="dropdown-item text-danger" href="#" appStopClick (click)="delete(g)">
                                <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
                                {{'delete' | i18n}}
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>
<ng-template #addEdit></ng-template>
<ng-template #usersTemplate></ng-template>
