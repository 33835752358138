<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="userAccessTitle">
    <div class="modal-dialog modal-lg" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="userAccessTitle">
                    {{'userAccess' | i18n}}
                    <small>{{entityName}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="loading || !users">
                <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                <span class="sr-only">{{'loading' | i18n}}</span>
            </div>
            <div class="modal-body"
                *ngIf="!loading && users && (users | search:searchText:'name':'email':'id') as searchedUsers">
                <div class="d-flex">
                    <div class="mr-3">
                        <label class="sr-only" for="search">{{'search' | i18n}}</label>
                        <input type="search" class="form-control form-control-sm" id="search"
                            placeholder="{{'search' | i18n}}" name="SearchText" [(ngModel)]="searchText">
                    </div>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-outline-secondary" [ngClass]="{active: !showSelected}"
                            (click)="filterSelected(false)">
                            {{'all' | i18n}}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" [ngClass]="{active: showSelected}"
                            (click)="filterSelected(true)">
                            {{'selected' | i18n}}
                            <span class="badge badge-pill badge-info" *ngIf="selectedCount">{{selectedCount}}</span>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="!searchedUsers.length">
                    <hr>
                    {{'noUsersInList' | i18n}}
                </ng-container>
                <ng-container *ngIf="searchedUsers.length">
                    <table class="table table-hover table-list mb-0">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>{{'name' | i18n}}</th>
                                <th *ngIf="entity === 'collection'">&nbsp;</th>
                                <th>{{'userType' | i18n}}</th>
                                <th width="100" class="text-center" *ngIf="entity === 'collection'">{{'hidePasswords' |
                                    i18n}}</th>
                                <th width="100" class="text-center" *ngIf="entity === 'collection'">{{'readOnly' |
                                    i18n}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let u of searchedUsers">
                                <td class="table-list-checkbox" (click)="check(u)">
                                    <input type="checkbox" [(ngModel)]="u.checked" name="{{u.id.substr(0,8)}}_Checked"
                                        [disabled]="entity === 'collection' && u.accessAll"
                                        (change)="selectedChanged(u)" appStopProp>
                                </td>
                                <td width="30" (click)="check(u)">
                                    <app-avatar [data]="u.name || u.email" [email]="u.email" size="25" [circle]="true"
                                        [fontSize]="14"></app-avatar>
                                </td>
                                <td>
                                    {{u.email}}
                                    <span class="badge badge-secondary"
                                        *ngIf="u.status === organizationUserStatusType.Invited">{{'invited'
                                        | i18n}}</span>
                                    <span class="badge badge-warning"
                                        *ngIf="u.status === organizationUserStatusType.Accepted">{{'accepted'
                                        | i18n}}</span>
                                    <small class="text-muted d-block" *ngIf="u.name">{{u.name}}</small>
                                </td>
                                <td *ngIf="entity === 'collection'">
                                    <ng-container *ngIf="u.accessAll">
                                        <i class="fa fa-th" title="{{'userAccessAllItems' | i18n}}"
                                            aria-hidden="true"></i>
                                        <span class="sr-only">{{'userAccessAllItems' | i18n}}</span>
                                    </ng-container>
                                </td>
                                <td>
                                    <span *ngIf="u.type === organizationUserType.Owner">{{'owner' | i18n}}</span>
                                    <span *ngIf="u.type === organizationUserType.Admin">{{'admin' | i18n}}</span>
                                    <span *ngIf="u.type === organizationUserType.Manager">{{'manager' | i18n}}</span>
                                    <span *ngIf="u.type === organizationUserType.User">{{'user' | i18n}}</span>
                                    <span *ngIf="u.type === organizationUserType.Custom">{{'custom' | i18n}}</span>
                                </td>
                                <td class="text-center" *ngIf="entity === 'collection'">
                                    <input type="checkbox" [(ngModel)]="u.hidePasswords"
                                        name="{{u.id.substr(0,8)}}_HidePasswords"
                                        [disabled]="u.accessAll || !u.checked">
                                </td>
                                <td class="text-center" *ngIf="entity === 'collection'">
                                    <input type="checkbox" [(ngModel)]="u.readOnly" name="{{u.id.substr(0,8)}}_ReadOnly"
                                        [disabled]="u.accessAll || !u.checked">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
