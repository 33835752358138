import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ToasterService } from 'angular2-toaster';
import { Angulartics2 } from 'angulartics2';

import { ApiService } from 'jslib/abstractions/api.service';
import { CollectionService } from 'jslib/abstractions/collection.service';
import { I18nService } from 'jslib/abstractions/i18n.service';
import { PlatformUtilsService } from 'jslib/abstractions/platformUtils.service';

import { CollectionData } from 'jslib/models/data/collectionData';
import { Collection } from 'jslib/models/domain/collection';
import { GroupRequest } from 'jslib/models/request/groupRequest';
import { SelectionReadOnlyRequest } from 'jslib/models/request/selectionReadOnlyRequest';
import { CollectionDetailsResponse } from 'jslib/models/response/collectionResponse';
import { CollectionView } from 'jslib/models/view/collectionView';

@Component({
    selector: 'app-group-add-edit',
    templateUrl: 'group-add-edit.component.html',
})
export class GroupAddEditComponent implements OnInit {
    @Input() groupId: string;
    @Input() organizationId: string;
    @Output() onSavedGroup = new EventEmitter();
    @Output() onDeletedGroup = new EventEmitter();

    loading = true;
    editMode: boolean = false;
    title: string;
    name: string;
    externalId: string;
    access: 'all' | 'selected' = 'selected';
    collections: CollectionView[] = [];
    formPromise: Promise<any>;
    deletePromise: Promise<any>;

    constructor(private apiService: ApiService, private i18nService: I18nService,
        private analytics: Angulartics2, private toasterService: ToasterService,
        private collectionService: CollectionService, private platformUtilsService: PlatformUtilsService) { }

    async ngOnInit() {
        this.editMode = this.loading = this.groupId != null;
        await this.loadCollections();

        if (this.editMode) {
            this.editMode = true;
            this.title = this.i18nService.t('editGroup');
            try {
                const group = await this.apiService.getGroupDetails(this.organizationId, this.groupId);
                this.access = group.accessAll ? 'all' : 'selected';
                this.name = group.name;
                this.externalId = group.externalId;
                if (group.collections != null && this.collections != null) {
                    group.collections.forEach(s => {
                        const collection = this.collections.filter(c => c.id === s.id);
                        if (collection != null && collection.length > 0) {
                            (collection[0] as any).checked = true;
                            collection[0].readOnly = s.readOnly;
                            collection[0].hidePasswords = s.hidePasswords;
                        }
                    });
                }
            } catch { }
        } else {
            this.title = this.i18nService.t('addGroup');
        }

        this.loading = false;
    }

    async loadCollections() {
        const response = await this.apiService.getCollections(this.organizationId);
        const collections = response.data.map(r =>
            new Collection(new CollectionData(r as CollectionDetailsResponse)));
        this.collections = await this.collectionService.decryptMany(collections);
    }

    check(c: CollectionView, select?: boolean) {
        (c as any).checked = select == null ? !(c as any).checked : select;
        if (!(c as any).checked) {
            c.readOnly = false;
        }
    }

    selectAll(select: boolean) {
        this.collections.forEach(c => this.check(c, select));
    }

    async submit() {
        const request = new GroupRequest();
        request.name = this.name;
        request.externalId = this.externalId;
        request.accessAll = this.access === 'all';
        if (!request.accessAll) {
            request.collections = this.collections.filter(c => (c as any).checked)
                .map(c => new SelectionReadOnlyRequest(c.id, !!c.readOnly, !!c.hidePasswords));
        }

        try {
            if (this.editMode) {
                this.formPromise = this.apiService.putGroup(this.organizationId, this.groupId, request);
            } else {
                this.formPromise = this.apiService.postGroup(this.organizationId, request);
            }
            await this.formPromise;
            this.analytics.eventTrack.next({ action: this.editMode ? 'Edited Group' : 'Created Group' });
            this.toasterService.popAsync('success', null,
                this.i18nService.t(this.editMode ? 'editedGroupId' : 'createdGroupId', this.name));
            this.onSavedGroup.emit();
        } catch { }
    }

    async delete() {
        if (!this.editMode) {
            return;
        }

        const confirmed = await this.platformUtilsService.showDialog(
            this.i18nService.t('deleteGroupConfirmation'), this.name,
            this.i18nService.t('yes'), this.i18nService.t('no'), 'warning');
        if (!confirmed) {
            return false;
        }

        try {
            this.deletePromise = this.apiService.deleteGroup(this.organizationId, this.groupId);
            await this.deletePromise;
            this.analytics.eventTrack.next({ action: 'Deleted Group' });
            this.toasterService.popAsync('success', null, this.i18nService.t('deletedGroupId', this.name));
            this.onDeletedGroup.emit();
        } catch { }
    }
}
