<div class="card border-primary">
    <div class="card-header bg-primary text-white">
        <i class="fa fa-paper-plane-o fa-fw" aria-hidden="true"></i>
        <a class="text-white" href="/#/sends">Bitwarden Send</a>
    </div>
    <div class="card-body">
            {{'sendVaultCardProductDesc' | i18n}}
            <a href="https://www.bitwarden.com/products/send?source=web-vault" target="_blank">{{'sendVaultCardLearnMore' |
                i18n}}</a>,
            {{'sendVaultCardSee' | i18n}}
            <a href="https://www.youtube.com/watch?v=AFtPP9bcuYM" target="_blank">{{'sendVaultCardHowItWorks' | i18n}}</a>,
            {{'sendVaultCardOr' | i18n}}
            <a href="/#/sends">{{'sendVaultCardTryItNow' | i18n}}</a>.
    </div>
</div>
