<div class="page-header">
    <h1>
        {{'inactive2faReport' | i18n}}
        <small *ngIf="hasLoaded && loading">
            <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
            <span class="sr-only">{{'loading' | i18n}}</span>
        </small>
    </h1>
</div>
<p>{{'inactive2faReportDesc' | i18n}}</p>
<div *ngIf="!hasLoaded && loading">
    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
    <span class="sr-only">{{'loading' | i18n}}</span>
</div>
<div class="mt-4" *ngIf="hasLoaded">
    <app-callout type="success" title="{{'goodNews' | i18n}}" *ngIf="!ciphers.length">
        {{'noInactive2fa' | i18n}}
    </app-callout>
    <ng-container *ngIf="ciphers.length">
        <app-callout type="danger" title="{{'inactive2faFound' | i18n}}">
            {{'inactive2faFoundDesc' | i18n : (ciphers.length | number)}}
        </app-callout>
        <table class="table table-hover table-list table-ciphers">
            <tbody>
                <tr *ngFor="let c of ciphers">
                    <td class="table-list-icon">
                        <app-vault-icon [cipher]="c"></app-vault-icon>
                    </td>
                    <td class="reduced-lh wrap">
                        <a href="#" appStopClick (click)="selectCipher(c)" title="{{'editItem' | i18n}}">{{c.name}}</a>
                        <ng-container *ngIf="!organization && c.organizationId">
                            <i class="fa fa-share-alt" appStopProp title="{{'shared' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'shared' | i18n}}</span>
                        </ng-container>
                        <ng-container *ngIf="c.hasAttachments">
                            <i class="fa fa-paperclip" appStopProp title="{{'attachments' | i18n}}"
                                aria-hidden="true"></i>
                            <span class="sr-only">{{'attachments' | i18n}}</span>
                        </ng-container>
                        <br>
                        <small>{{c.subTitle}}</small>
                    </td>
                    <td class="text-right">
                        <a class="badge badge-primary" href="{{cipherDocs.get(c.id)}}" target="_blank" rel="noopener"
                            *ngIf="cipherDocs.has(c.id)">
                            {{'instructions' | i18n}}</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<ng-template #cipherAddEdit></ng-template>
