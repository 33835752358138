<ng-container *ngIf="vault">
    <p *ngIf="!loaded" class="text-muted">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span class="sr-only">{{'loading' | i18n}}</span>
    </p>
    <ng-container *ngIf="loaded">
        <ul class="fa-ul card-ul carets" *ngIf="organizations && organizations.length">
            <li *ngFor="let o of organizations">
                <a [routerLink]="['/organizations', o.id]" class="text-body">
                    <i class="fa-li fa fa-caret-right" aria-hidden="true"></i> {{o.name}}
                    <ng-container *ngIf="!o.enabled">
                        <i class="fa fa-exclamation-triangle text-danger" title="{{'organizationIsDisabled' | i18n}}"
                            aria-hidden="true"></i>
                        <span class="sr-only">{{'organizationIsDisabled' | i18n}}</span>
                    </ng-container>
                </a>
            </li>
        </ul>
        <p *ngIf="!organizations || !organizations.length">{{'noOrganizationsList' | i18n}}</p>
    </ng-container>
    <a href="#" routerLink="/settings/create-organization" class="btn btn-block btn-outline-primary">
        <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
        {{'newOrganization' | i18n}}
    </a>
</ng-container>
<ng-container *ngIf="!vault">
    <div class="page-header d-flex">
        <h1>
            {{'organizations' | i18n}}
            <small [appApiAction]="actionPromise" #action>
                <ng-container *ngIf="action.loading">
                    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span class="sr-only">{{'loading' | i18n}}</span>
                </ng-container>
            </small>
        </h1>
        <a href="#" routerLink="/settings/create-organization" class="btn btn-sm btn-outline-primary ml-auto"
            *ngIf="!loaded || (organizations && organizations.length)">
            <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
            {{'newOrganization' | i18n}}
        </a>
    </div>
    <ng-container *ngIf="!loaded">
        <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span class="sr-only">{{'loading' | i18n}}</span>
    </ng-container>
    <ng-container *ngIf="loaded">
        <ng-container *ngIf="!organizations || !organizations.length">
            <p>{{'noOrganizationsList' | i18n}}</p>
            <a href="#" routerLink="/settings/create-organization" class="btn btn-outline-primary">
                <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
                {{'newOrganization' | i18n}}
            </a>
        </ng-container>
        <table class="table table-hover table-list" *ngIf="organizations && organizations.length">
            <tbody>
                <tr *ngFor="let o of organizations">
                    <td width="30">
                        <app-avatar [data]="o.name" size="25" [circle]="true" [fontSize]="14"></app-avatar>
                    </td>
                    <td>
                        <a href="#" [routerLink]="['/organizations', o.id]">{{o.name}}</a>
                        <ng-container *ngIf="!o.enabled">
                            <i class="fa fa-exclamation-triangle text-danger"
                                title="{{'organizationIsDisabled' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'organizationIsDisabled' | i18n}}</span>
                        </ng-container>
                    </td>
                    <td class="table-list-options">
                        <div class="dropdown" appListDropdown>
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                appA11yTitle="{{'options' | i18n}}">
                                <i class="fa fa-cog fa-lg" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <ng-container *ngIf="o.useSso && o.identifier">
                                    <a *ngIf="o.ssoBound; else linkSso" class="dropdown-item" href="#" appStopClick
                                        (click)="unlinkSso(o)">
                                        <i class="fa fa-fw fa-chain-broken" aria-hidden="true"></i>
                                        {{'unlinkSso' | i18n}}
                                    </a>
                                    <ng-template #linkSso>
                                        <app-link-sso [organization]="o">
                                        </app-link-sso>
                                    </ng-template>
                                </ng-container>
                                <a class="dropdown-item text-danger" href="#" appStopClick (click)="leave(o)">
                                    <i class="fa fa-fw fa-sign-out" aria-hidden="true"></i>
                                    {{'leave' | i18n}}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</ng-container>
