<div class="page-header d-flex">
    <h1>{{'people' | i18n}}</h1>
    <div class="ml-auto d-flex">
        <div class="btn-group btn-group-sm" role="group">
            <button type="button" class="btn btn-outline-secondary" [ngClass]="{active: status == null}"
                (click)="filter(null)">
                {{'all' | i18n}}
                <span class="badge badge-pill badge-info" *ngIf="allCount">{{allCount}}</span>
            </button>
            <button type="button" class="btn btn-outline-secondary"
                [ngClass]="{active: status == organizationUserStatusType.Invited}"
                (click)="filter(organizationUserStatusType.Invited)">
                {{'invited' | i18n}}
                <span class="badge badge-pill badge-info" *ngIf="invitedCount">{{invitedCount}}</span>
            </button>
            <button type="button" class="btn btn-outline-secondary"
                [ngClass]="{active: status == organizationUserStatusType.Accepted}"
                (click)="filter(organizationUserStatusType.Accepted)">
                {{'accepted' | i18n}}
                <span class="badge badge-pill badge-warning" *ngIf="acceptedCount">{{acceptedCount}}</span>
            </button>
        </div>
        <div class="ml-3">
            <label class="sr-only" for="search">{{'search' | i18n}}</label>
            <input type="search" class="form-control form-control-sm" id="search" placeholder="{{'search' | i18n}}"
                [(ngModel)]="searchText">
        </div>
        <button type="button" class="btn btn-sm btn-outline-primary ml-3" (click)="invite()">
            <i class="fa fa-plus fa-fw" aria-hidden="true"></i>
            {{'inviteUser' | i18n}}
        </button>
    </div>
</div>
<ng-container *ngIf="loading">
    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
    <span class="sr-only">{{'loading' | i18n}}</span>
</ng-container>
<ng-container
    *ngIf="!loading && (isPaging() ? pagedUsers : users | search:searchText:'name':'email':'id') as searchedUsers">
    <p *ngIf="!searchedUsers.length">{{'noUsersInList' | i18n}}</p>
    <ng-container *ngIf="searchedUsers.length">
        <app-callout type="info" title="{{'confirmUsers' | i18n}}" icon="fa-check-circle" *ngIf="showConfirmUsers">
            {{'usersNeedConfirmed' | i18n}}
        </app-callout>
        <table class="table table-hover table-list" infiniteScroll [infiniteScrollDistance]="1"
            [infiniteScrollDisabled]="!isPaging()" (scrolled)="loadMore()">
            <tbody>
                <tr *ngFor="let u of searchedUsers">
                    <td width="30">
                        <app-avatar [data]="u.name || u.email" [email]="u.email" size="25" [circle]="true"
                            [fontSize]="14"></app-avatar>
                    </td>
                    <td>
                        <a href="#" appStopClick (click)="edit(u)">{{u.email}}</a>
                        <span class="badge badge-secondary"
                            *ngIf="u.status === organizationUserStatusType.Invited">{{'invited' | i18n}}</span>
                        <span class="badge badge-warning"
                            *ngIf="u.status === organizationUserStatusType.Accepted">{{'accepted' | i18n}}</span>
                        <small class="text-muted d-block" *ngIf="u.name">{{u.name}}</small>
                    </td>
                    <td>
                        <ng-container *ngIf="u.twoFactorEnabled">
                            <i class="fa fa-lock" title="{{'userUsingTwoStep' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'userUsingTwoStep' | i18n}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <span *ngIf="u.type === organizationUserType.Owner">{{'owner' | i18n}}</span>
                        <span *ngIf="u.type === organizationUserType.Admin">{{'admin' | i18n}}</span>
                        <span *ngIf="u.type === organizationUserType.Manager">{{'manager' | i18n}}</span>
                        <span *ngIf="u.type === organizationUserType.User">{{'user' | i18n}}</span>
                        <span *ngIf="u.type === organizationUserType.Custom">{{'custom' | i18n}}</span>
                    </td>
                    <td class="table-list-options">
                        <div class="dropdown" appListDropdown>
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                appA11yTitle="{{'options' | i18n}}">
                                <i class="fa fa-cog fa-lg" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" appStopClick (click)="reinvite(u)"
                                    *ngIf="u.status === organizationUserStatusType.Invited">
                                    <i class="fa fa-fw fa-envelope-o" aria-hidden="true"></i>
                                    {{'resendInvitation' | i18n}}
                                </a>
                                <a class="dropdown-item text-success" href="#" appStopClick (click)="confirm(u)"
                                    *ngIf="u.status === organizationUserStatusType.Accepted">
                                    <i class="fa fa-fw fa-check" aria-hidden="true"></i>
                                    {{'confirm' | i18n}}
                                </a>
                                <a class="dropdown-item" href="#" appStopClick (click)="groups(u)" *ngIf="accessGroups">
                                    <i class="fa fa-fw fa-sitemap" aria-hidden="true"></i>
                                    {{'groups' | i18n}}
                                </a>
                                <a class="dropdown-item" href="#" appStopClick (click)="events(u)"
                                    *ngIf="accessEvents && u.status === organizationUserStatusType.Confirmed">
                                    <i class="fa fa-fw fa-file-text-o" aria-hidden="true"></i>
                                    {{'eventLogs' | i18n}}
                                </a>
                                <a class="dropdown-item text-danger" href="#" appStopClick (click)="remove(u)">
                                    <i class="fa fa-fw fa-remove" aria-hidden="true"></i>
                                    {{'remove' | i18n}}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</ng-container>
<ng-template #addEdit></ng-template>
<ng-template #groupsTemplate></ng-template>
<ng-template #eventsTemplate></ng-template>
<ng-template #confirmTemplate></ng-template>
