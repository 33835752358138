<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="collectionAddEditTitle">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="collectionAddEditTitle">{{title}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="loading">
                <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                <span class="sr-only">{{'loading' | i18n}}</span>
            </div>
            <div class="modal-body" *ngIf="!loading">
                <div class="form-group">
                    <label for="name">{{'name' | i18n}}</label>
                    <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="name" required
                        appAutofocus>
                </div>
                <div class="form-group">
                    <label for="externalId">{{'externalId' | i18n}}</label>
                    <input id="externalId" class="form-control" type="text" name="ExternalId" [(ngModel)]="externalId">
                    <small class="form-text text-muted">{{'externalIdDesc' | i18n}}</small>
                </div>
                <ng-container *ngIf="accessGroups">
                    <h3 class="mt-4 d-flex mb-0">
                        {{'groupAccess' | i18n}}
                        <div class="ml-auto" *ngIf="groups && groups.length">
                            <button type="button" (click)="selectAll(true)" class="btn btn-link btn-sm py-0">
                                {{'selectAll' | i18n}}
                            </button>
                            <button type="button" (click)="selectAll(false)" class="btn btn-link btn-sm py-0">
                                {{'unselectAll' | i18n}}
                            </button>
                        </div>
                    </h3>
                    <div *ngIf="!groups || !groups.length">
                        {{'noGroupsInList' | i18n}}
                    </div>
                    <table class="table table-hover table-list mb-0" *ngIf="groups && groups.length">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>{{'name' | i18n}}</th>
                                <th width="100" class="text-center">{{'hidePasswords' | i18n}}</th>
                                <th width="100" class="text-center">{{'readOnly' | i18n}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let g of groups; let i = index">
                                <td class="table-list-checkbox" (click)="check(g)">
                                    <input type="checkbox" [(ngModel)]="g.checked" name="Groups[{{i}}].Checked"
                                        [disabled]="g.accessAll" appStopProp>
                                </td>
                                <td (click)="check(g)">
                                    {{g.name}}
                                    <ng-container *ngIf="g.accessAll">
                                        <i class="fa fa-th text-muted fa-fw" title="{{'groupAccessAllItems' | i18n}}"
                                            aria-hidden="true"></i>
                                        <span class="sr-only">{{'groupAccessAllItems' | i18n}}</span>
                                    </ng-container>
                                </td>
                                <td class="text-center">
                                    <input type="checkbox" [(ngModel)]="g.hidePasswords"
                                        name="Groups[{{i}}].HidePasswords" [disabled]="!g.checked || g.accessAll">
                                </td>
                                <td class="text-center">
                                    <input type="checkbox" [(ngModel)]="g.readOnly" name="Groups[{{i}}].ReadOnly"
                                        [disabled]="!g.checked || g.accessAll">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    data-dismiss="modal">{{'cancel' | i18n}}</button>
                <div class="ml-auto">
                    <button #deleteBtn type="button" (click)="delete()" class="btn btn-outline-danger"
                        appA11yTitle="{{'delete' | i18n}}" *ngIf="editMode" [disabled]="deleteBtn.loading"
                        [appApiAction]="deletePromise">
                        <i class="fa fa-trash-o fa-lg fa-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
                        <i class="fa fa-spinner fa-spin fa-lg fa-fw" [hidden]="!deleteBtn.loading"
                            title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
